import api from '@/apis/config'
const { post } = api
const { get } = api
/**
 * 전자서명 안내 알림톡/LMS 발송, 설치희망일 변경알림 PUSH 및 계약서 재발송
 */
export const sendAlimTalk = async params => {
	const response = await post('v1/06/01', {
		...params
	})
	return response
}

/**
 * 인증페이지 만료여부 확인
 */
export const chkAuthExpired = async params => {
	const response = await post('v1/06/03', {
		...params
	})
	return response
}

/**
 * 전자서명을 위한 인증번호 발급
 * @param {string} name: 이름
 * @param {string} phoneNumber: 전화번호
 */
export const sendAuthNo = async user => {
	const response = await post('v1/06/04', {
		...user
	})
	return response
}

/**
 * 전자서명을 위한 인증번호 만료여부 확인
 * @param {string} name: 이름
 * @param {string} phoneNumber: 전화번호
 * @param {string} certNo: 인증번호
 */
export const validAuthNo = async user => {
	const response = await post('v1/06/05', {
		...user
	})
	return response
}

/**
 * 계약확인
 * @param {string} orderNo: 주문번호
 */
export const fetchEContract = async params => {
	const response = await post('v1/06/06', {
		...params
	})
	return response
}

/**
 * 계약확인
 * @param {string} orderNo: 주문번호
 */
export const fetchEContractBySapOrderNo = async params => {
	const response = await post('v1/06/07', {
		...params
	})
	return response
}

/**
 * 전자서명 확인
 * @param {string} orderNo: 주문번호
 */
export const fetchECustByCheckOrderNo = async orderNo => {
	const response = await get(`v1/06/08/${orderNo}`)
	return response
}

/**
 * 전자계약 약관및동의 history
 * @param {string} webOrderNo: 주문번호
 */
export const fetchEAgreeHistory = async (orderNo, params) => {
	const response = await post(`v1/06/09/${orderNo}`, {
		...params
	})
	return response
}

/**
 * 전자서명을 위한 쿠키 생성 후 암호화된 값 반환
 * @param {string} webOrderNo: 주문번호
 * @param {string} phoneNumber: 전화번호
 */
export const fetchCreateEcCookie = async params => {
	const response = await post(
		'v1/06/10',
		{
			...params
		},
		false,
		true
	)
	return response
}

/**
 * 주문확정
 * @param {string} orderNo: 주문번호
 */
export const fetchOrderConfirm = async params => {
	const response = await post('v1/08/01', {
		...params
	})
	return response
}

/**
 * 주문취소
 * @param {string} params: 주문번호
 */
export const postOrderCancel = async params => {
	const response = await post('v1/09/01', {
		...params
	})
	return response
}

/**
 * 주문진행현황 조회 권한 확인 (영업정보시스템)
 * @param pernr : 사원번호 (stinrg)
 * @param orgeh : 조직단위 (string)
 * @param userNo : 사용자번호 (string)
 */
export const salesAuthCheck = async (pernr, orgeh, userNo) => {
	const response = await get(`v1/09/02/${pernr}/${orgeh}/${userNo}`)
	return response
}

/**
 * 수납여부 체크
 * @param orderNo : string (주문번호)
 * @returns {Promise<*>}
 */
export const paymentRecpCheck = async orderNo => {
	const response = await get(`v1/07/01/${orderNo}`)
	return response
}
