import { fetchEncrypedKeyForCustVerification } from '@/apis/order.verify.api'
import Vue from 'vue'

/**
 * 고객인증을 위한 PASS 인증 호출
 * @param {*} retUrl 인증후 redirect url
 */
export const openPassVerification = (serviceNo, retUrl) => {
	fetchEncrypedKeyForCustVerification(serviceNo)
		.then(res => {
			const reqInfo = res.resultObject.reqInfo
			openPassWindow(reqInfo, retUrl)
		})
		.catch(error => {
			throw error
		})
}

/**
 * 고객인증을 위한 PASS 인증창 호출
 * @param {*} reqInfo 인증요청을 위한 암호화된 값
 * @param {*} retUrl 인증후 redirect url
 */
const openPassWindow = async (reqInfo, retUrl) => {
	const pccWindow = window.open(
		'',
		'PCCV3Window',
		'width=400, height=630, resizable=1, scrollbars=no, status=0, titlebar=0, toolbar=0, location=no'
	)
	if (pccWindow == null) {
		await Vue.$alert({
			message: `※ 모바일 화면에서 팝업이 차단된 경우, 본인 인증이 제대로 진행되지 않을 수 있습니다 <br/>
			안드로이드/네이버/모바일 구글 크롬/사파리(아이폰) 브라우저를 사용하시는 경우<br/> 설정에서 팝업차단을 해제해 주시기 바랍니다. <br/><br/>
					※ MSN,야후,구글 팝업 차단 툴바가 설치된 경우 팝업허용을 해주시기 바랍니다.`,
			okText: '확인'
		})
		return
	}

	let body = document.querySelector('body')

	body.insertAdjacentHTML(
		'beforeend',
		`<form id="reqPCCForm" name="reqPCCForm" 
				action="https://pcc.siren24.com/pcc_V3/jsp/pcc_V3_j10_v2.jsp" 
				method="post" target="PCCV3Window">
        	<input type="hidden" name="reqInfo" value="${reqInfo}">
			<input type="hidden" name="retUrl" value="${retUrl}">
			<input type="hidden" name="verSion" value="2">
      	</form>`
	)
	document.getElementById('reqPCCForm').submit()
	const el = document.getElementById('reqPCCForm')
	el.parentNode.removeChild(el)
}
