<template>
	<div class="order_wrap"></div>
</template>
<script>
import { openPassVerification } from '@/utils/pass'
import { chkAuthExpired } from '@/apis/order.ec.api'
import { fetchCustPass72Check } from '@/apis/order.verify.api'
export default {
	data() {
		return {
			retUrl: `31${window.location.protocol}//${window.location.host}/order/ec/cust/confirm`
		}
	},
	async mounted() {
		const { userKey } = this.$route.query
		const res = await chkAuthExpired({ userKey })

		if (res.resultObject.valid) {
			sessionStorage.setItem('userKey', userKey)
			const info = res.resultObject.orderInfo

			const json = JSON.parse(res.resultObject.orderInfo.orderInfoJson)
			if (json.recp?.isCustSelfPay && info.statusCode !== '108') {
				sessionStorage.setItem('customer', JSON.stringify(json.customer))
				this.retUrl =
					`31${window.location.protocol}//${window.location.host}/order/recp/cust/method?userKey=` +
					userKey
			}

			let custParams = {}
			if (info) {
				const json = JSON.parse(info.orderInfoJson)
				this.$log('json', json.products)
				this.$store.dispatch('pay/savePayPrd', json.products)
				sessionStorage.setItem('orderId', res.resultObject.orderInfo.id)

				custParams = {
					name: json.customer.name,
					phoneNo: json.customer.phone,
					id: res.resultObject.orderInfo.id
				}
				const pass72Res = await fetchCustPass72Check(custParams)

				if (
					(pass72Res.resultObject.decrypt && !json.recp?.isCustSelfPay) ||
					// custPay
					(info.statusCode === '108' && pass72Res.resultObject.decrypt)
				) {
					this.$router.push({
						name: 'ec-cust-confirm',
						params: { pass72Res: pass72Res.resultObject.decrypt }
					})
				} else if (pass72Res.resultObject.decrypt && json.recp?.isCustSelfPay) {
					this.$router.push({
						name: 'recp-cust-method',
						query: {
							userKey: userKey
						},
						params: {
							decrypt: pass72Res.resultObject.decrypt
						}
					})
				} else {
					openPassVerification(
						process.env.VUE_APP_EC_PASS_SERVICE_NO_MWB,
						this.retUrl
					)
				}
			} else {
				openPassVerification(
					process.env.VUE_APP_EC_PASS_SERVICE_NO_MWB,
					this.retUrl
				)
			}
		} else {
			this.$router.push({ name: 'ec-cust-expired' })
		}
	}
}
</script>
